import React from "react";
import { Link } from "react-router-dom";

import logo from  "../assets/images/footer-logo.png"
import "./assets/index.css"


const Footer = () => {
    return (
        <>
        <footer className="footer-distributed footer_mobile">
            <section className="accordion accordion--radio">
                <div className="tab">
                    <input type="radio" name="accordion-2" id="rd1" />
                    <label htmlFor="rd1" className="tab__label"><img src ={logo} alt="footer-logo" height="60px" /></label>
                    <div className="tab__content">                    
                        <p>Sun creations is an organization that specialize in creating and developing visual concepts and designs.</p>
                    </div>
                </div>
                <div className="tab">
                    <input type="radio" name="accordion-2" id="rd2" />
                    <label htmlFor="rd2" className="tab__label">CONTACT WITH US</label>
                    <div className="tab__content">
                    <p>Phone: <a href="tel:+91-8840966725">+91-8840966725</a></p>
                    <p>Email: <a href="mailto:info.suncreations@gmail.com">info.suncreations@gmail.com</a></p>
                    <p>Working Day :  Monday - Saturday <br /> Timings :  10:00 AM - 7:30 PM</p>
                    </div>
                </div>
                <div className="tab">
                    <input type="radio" name="accordion-2" id="rd3" />
                    <label htmlFor="rd3" className="tab__label">QUICK LINKS</label>
                    <div className="tab__content">
                        <ul>
                            <li><a href="/">Home<i className="material-icons"></i></a></li>
                            <li><Link to="/about-us">About Us<i className="material-icons"></i></Link></li>
                            <li><Link to="/our-service">Our Services<i className="material-icons"></i></Link></li>
                            <li><Link to="/our-portfolio">Our Portfolio<i className="material-icons"></i></Link></li>
                            <li><Link to="/contact-us">Contact Us<i className="material-icons"></i></Link></li>
                            <li><Link to="/enquiry">Enquiry<i className="material-icons"></i></Link></li>
                        </ul>
                    </div>
                </div>
                <div className="tab">
                    <input type="radio" name="accordion-2" id="rd4" />                    
                    <label htmlFor="rd4" className="tab__label">FOLLOW US</label>
                    <div className="tab__content">
                        <ul className="social_icon_footer">
                            <li><a><i className="fa fa-instagram"></i></a></li>
                            <li><a><i className="fa fa-facebook"></i></a></li>
                            <li><a><i className="fa fa-youtube"></i></a></li>
                        </ul>
                    </div>
                </div>
            </section>
        </footer>
        <div className="copyright copyright_mobile"> <p className="footer-company-name">All Rights Reserved Sun Creation © 2024</p></div>
<footer className="footer-distributed footer_desktop">
<div className="footer_col4 footer_comman_style">
        <img src ={logo} alt="footer-logo" height="60px" />
        <p>Sun creations is an organization that specialize in creating and developing visual concepts and designs.</p>
    </div>
    <div className="footer_col2 footer_comman_style">
        <h4>CONTACT WITH US</h4>
        <p>Phone: <a href="tel:+91-8840966725">+91-8840966725</a></p>
        <p>Email: <a href="mailto:info.suncreations@gmail.com">info.suncreations@gmail.com</a></p>
        <p>Working Day :  Monday - Saturday <br /> Timings :  10:00 AM - 7:30 PM</p>
    </div>
    <div className="footer_col1 footer_comman_style">
        <h4>QUICK LINKS</h4>
        <ul>
            <li><a href="/">Home<i className="material-icons"></i></a></li>
            <li><Link to="/about-us">About Us<i className="material-icons"></i></Link></li>
            <li><Link to="/privacy">Privacy Policy<i className="material-icons"></i></Link></li>
            <li><Link to="/terms">Terms<i className="material-icons"></i></Link></li>
            <li><Link to="/contact-us">Contact Us<i className="material-icons"></i></Link></li>
            {/* <li><Link to="/enquiry">Enquiry<i className="material-icons"></i></Link></li>
        */}
        </ul>
    </div>
    <div className="footer_col3 footer_comman_style">
        <h4>FOLLOW US</h4>
        <ul>
            <li><a><i className="fa fa-instagram"></i></a></li>
            <li><a><i className="fa fa-facebook"></i></a></li>
            <li><a><i className="fa fa-youtube"></i></a></li>
        </ul>
    </div>
            
</footer>
<div className="copyright footer_desktop"> <p className="footer-company-name">All Rights Reserved Sun Creation © 2024</p></div>
</>
    )
    
}

export default Footer