import React from "react";
import { Container } from "@mui/material";
import { Link } from 'react-router-dom'

import Header from "../../common/header";
import Footer from "../../common/footer";


import arcylic1 from "../images/portofloio.jpg"
import mansory1 from "../images/img001.jpg"
import mansory3 from "../images/img004.jpg"
import mansory4 from "../images/img006.jpg"
import mansory8 from "../images/img007.jpg"
import mansory6 from "../images/img008.jpg"

import { Helmet } from "react-helmet";
const  Service = (() =>{

return(
<>
<Header >
<Helmet>
<title>Our Services: Premium Graphic Prints, Acrylic Glass Prints  and other prinitng Solutions 
</title>   <meta name="keywords" content="graphic prints,acrylic glass prints,LED letters,solid acrylic letters,premium printing services,custom signage,business signage solutions,personalized prints,interior design signage,corporate branding "/>
            <meta name="description" content="Discover exquisite graphic prints, stunning acrylic glass prints, bespoke LED letters, and durable solid acrylic letters "/>
        
          </Helmet>
</Header>
<Container >
    
<section className="arcylic_sec">
        <h1>Our Services</h1>
        <p>Arcyclic photo print media has revolutionized the way we display and appreciate visual art. These prints, characterized by their vibrant colors and remarkable depth, offer a unique visual experience that traditional printing methods struggle to match.</p>
        <p>One of the standout features of arcyclic prints is their exceptional durability. Unlike paper prints that may fade or degrade over time, arcyclic prints are known for their long-lasting vibrancy.</p>
        <div className="arcylic_img">
            <div className="arcylic_col">
              <Link to ="/portfolio/acrylic-glass-print"><div className="click_here">Click here!</div></Link>
              <img src={arcylic1} alt="acrylic-glass-print" />
              <span className="portfolio_title">ACRYLIC GLASS PRINT</span>
            </div>
            <div className="arcylic_col">
              <Link to ="/portfolio/outdoor-indoor-led-screens"><div className="click_here">Click here!</div></Link>
              <img src={mansory3} alt="outdoor-indoor-led-screens" />
              <span className="portfolio_title">OUTDOOR/INDOOR LED SCREENS</span>
            </div>
            <div className="arcylic_col">
              <Link to ="/portfolio/led-letters-channel-letters"><div className="click_here">Click here!</div></Link>
              <img src={mansory4} alt="led-letters-channel-letters" />
              <span className="portfolio_title">LED LETTERS/CHANNEL LETTERS</span>
            </div>
            <div className="arcylic_col">
              <Link to ="/portfolio/solid-acrylic-letters"><div className="click_here">Click here!</div></Link>
              <img src={mansory6} alt="solid-acrylic-letters" />
              <span className="portfolio_title">SOLID ACRYLIC LETTERS</span>
            </div>
            <div className="arcylic_col">
              <Link to ="/portfolio/fabrication-acp-hpl-cladding"><div className="click_here">Click here!</div></Link>
              <img src={mansory8} alt="fabrication-acp-hpl-cladding" />
              <span className="portfolio_title">Fabrication/ACP/HPL Cladding</span>
            </div>
            <div className="arcylic_col">
              <Link to ="/portfolio/retro-reflective-road-signs"><div className="click_here">Click here!</div></Link>
              <img src={mansory1} alt="retro-reflective-road-signs" />
              <span className="portfolio_title">Retro Reflective Road Signs</span>
            </div>           
        </div>
      </section>
</Container>
<Footer />
</>)

})

export default Service;