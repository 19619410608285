import React from "react";
import Header from "../../common/header";
import Footer from "../../common/footer";
import Offer from '../../common//offer'
import { Link } from 'react-router-dom'
import { Container } from "@mui/material";
import { Helmet } from "react-helmet";

import mansory1 from "../images/img001.jpg"
import mansory2 from "../images/img002.jpg"
import mansory5 from "../images/img003.jpg"
import mansory3 from "../images/img004.jpg"
import mansory7 from "../images/img005.jpg"
import mansory4 from "../images/img006.jpg"
import mansory8 from "../images/img007.jpg"
import mansory6 from "../images/img008.jpg"
import mansory9 from "../images/img009.jpg"
const  Portfolio = (() =>{

return(
<>
<Header >
<Helmet>
<title>Our Portfolio: Premium Graphic Prints, Acrylic Glass Prints  and other prinitng Solutions 
</title>   <meta name="keywords" content="graphic prints,acrylic glass prints,LED letters,solid acrylic letters,premium printing services,custom signage,business signage solutions,personalized prints,interior design signage,corporate branding "/>
            <meta name="description" content="Discover exquisite graphic prints, stunning acrylic glass prints, bespoke LED letters, and durable solid acrylic letters "/>
        
          </Helmet>
</Header>
<Container className="portfolio">
<section id="tours" className="home-quick-view">
        <div className="home-main1">
          <div className="home-heading01">
            <h2 className="home-header02">SS Creations- Portfolio</h2>
            <p className="home-caption01">
            We have a wide range of printing collections that ensure your projects stand out.
            </p>
          </div>
        </div>
        <div className="home-offers">
          <Link to="/">
            <div className="home-offer-container">
              <Offer image={mansory1}></Offer>
            </div>
          </Link>
          <Link to="/">
            <div className="home-offer-container1">
              <Offer
              image={mansory2}
    
              ></Offer>
            </div>
          </Link>
          <Link to="/">
            <div className="home-offer-container2">
              <Offer
               image={mansory3}
 
              ></Offer>
            </div>
          </Link>
          <Link to="/">
            <div className="home-offer-container3">
              <Offer
               image={mansory4}

              ></Offer>
            </div>
          </Link>
          <Link to="/">
            <div className="home-offer-container4">
              <Offer
               image={mansory5}

              ></Offer>
            </div>
          </Link>
          <Link to="/">
            <div className="home-offer-container5">
              <Offer
               image={mansory6}
          
              ></Offer>
            </div>
          </Link>
          <Link to="/">
            <div className="home-offer-container6">
              <Offer
               image={mansory7}
 
              ></Offer>
            </div>
          </Link>
          <Link to="/">
            <div className="home-offer-container7">
              <Offer
                image={mansory8}
              ></Offer>
            </div>
          </Link>
          <Link to="/">
            <div className="home-offer-container8">
              <Offer
               image={mansory9}
              ></Offer>
            </div>
          </Link>
        </div>
      
      </section>
</Container>
<Footer />
</>)

})

export default Portfolio;