import React from "react";
import Menu from "./menu";

import "./assets/index.css"
const Header =() => {

    return (
        
        <Menu />

  
     
    )
}

export default Header;