import React, { useEffect,useState } from "react";
import {Helmet} from "react-helmet";

import { useParams } from "react-router-dom";

import Header from "../../common/header";
import Footer from "../../common/footer";
import PortfolioLayout from "../../common/layoutportfolio";

import portfolio1 from '../images/portfolio1.jpg'
import portfolio2 from '../images/portfolio2.jpg'
import portfolio3 from '../images/portfolio3.jpg'
import portfolio4 from '../images/portfolio4.jpg'
import portfolio5 from '../images/portfolio6.jpg'

import mansory1 from "../images/img001.jpg"
import mansory2 from "../images/img002.jpg"
import mansory5 from "../images/img003.jpg"
import mansory3 from "../images/img004.jpg"
import mansory7 from "../images/img005.jpg"
import mansory4 from "../images/img006.jpg"
import mansory8 from "../images/img007.jpg"
import mansory6 from "../images/img008.jpg"
import mansory9 from "../images/img009.jpg"
import mansory10 from "../images/img0011.jpg"
import mansory11 from "../images/img0011.jpg"
import mansory12 from "../images/img0012.jpg"
import mansory13 from "../images/img0013.jpg"

const SpecificPortfolio = () => {
  const params = useParams();
  
  const [filter_portfolio,setFilterPortfolio] = useState([
    {
    category: '',
    title: '',
    url: [mansory1],
    content:''
    }

  ]);
  let portfolio_details = [
    {
      category: 'acrylic-glass-print',
      title: 'Acrylic Glass Print',
      url: [portfolio1,portfolio2,portfolio3,portfolio4,portfolio5],
      content:"Acrylic glass prints are modern, sleek displays where a photo or artwork is sandwiched between acrylic and a backing, often aluminum dibond. The acrylic's transparent, glossy finish enhances colors, depth, and durability, suitable for indoor or outdoor use. Face mounting ensures a secure, vibrant display with a high-definition appearance that makes images stand out. Customizable in size and shape, they offer a professional, contemporary look, though they require careful handling to prevent scratches."
    },
    {
      category: 'outdoor-indoor-led-screens',
      title: 'Outdoor Indoor Led Screen',
      url: [mansory2,mansory3,mansory6],
      content: "Indoor and outdoor signs serve distinct purposes based on location and environment. Indoor signs guide, inform, and brand within enclosed spaces, using materials like acrylic, metal, or vinyl to convey messages effectively. Outdoor signs withstand weather conditions and attract attention, typically made from durable materials like aluminum, PVC, or corrugated plastic. Both types enhance communication and branding strategies, ensuring clarity and visibility in their respective settings."
      },
      {
        category: 'led-letters-channel-letters',
        title: 'Led Letters Channel Letters',
        url: [mansory7,mansory13,mansory4],
        content: "LED letters and channel letters are both popular choices for signage, each offering unique advantages. LED letters utilize illuminated LEDs inside the letters, providing energy efficiency and high visibility day and night. They are ideal for creating eye-catching displays that stand out in various lighting conditions. Channel letters, on the other hand, are three-dimensional signs with individual elements and hollow backsides for internal lighting. This design allows for a bold, professional look and is customizable in size, font, and color to suit branding noods. Both types are widely used for storefronts, businesses, and outdoor advertising due to their versatility and impact."
        },
        {
          category: 'solid-acrylic-letters',
          title: 'Solid Acrylic Letters',
          url: [mansory10,mansory11,mansory12],
          content: "Solid acrylic letters are crafted from durable acrylic material, offering a sleek and modern appearance for signage. They are often used in indoor and outdoor settings where a clean, dimensional look is desired. Available in various colors and finishes, they enhance branding and visibility effectively."
          },
        {
          category: 'fabrication-acp-hpl-cladding',
          title: 'Fabrication/ACP/HPL Cladding',
          url: [mansory13,mansory8,mansory9],
          content: "Solid acrylic letters are crafted from durable acrylic material, offering a sleek and modern appearance for signage. They are often used in indoor and outdoor settings where a clean, dimensional look is desired. Available in various colors and finishes, they enhance branding and visibility effectively."
          },
        {
          category: 'retro-reflective-road-signs',
          title: 'Retro Reflective Road Signs',
          url: [mansory1,mansory5,mansory6],
          content: "Solid acrylic letters are crafted from durable acrylic material, offering a sleek and modern appearance for signage. They are often used in indoor and outdoor settings where a clean, dimensional look is desired. Available in various colors and finishes, they enhance branding and visibility effectively."
          }
  ]

  useEffect(() => {
let str=params.category.trim();
const result =portfolio_details.filter((item) => {
return item.category === str;
})
setFilterPortfolio(result)
  },[params.category])
    return (
        <>
        <Header />
        <Helmet>
<title>{filter_portfolio[0].title} : Premium Graphic Prints, Acrylic Glass Prints  and other prinitng Solutions 
</title>   <meta name="keywords" content="graphic prints,acrylic glass prints,LED letters,solid acrylic letters,premium printing services,custom signage,business signage solutions,personalized prints,interior design signage,corporate branding "/>
            <meta name="description" content="Discover exquisite graphic prints, stunning acrylic glass prints, bespoke LED letters, and durable solid acrylic letters "/>
        
          </Helmet>
    <div> <PortfolioLayout title={filter_portfolio[0].title}
      content ={filter_portfolio[0].content}
      images ={filter_portfolio[0].url} /></div><Footer /></>
    )
}

export default SpecificPortfolio